/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../../components/layout';
import Hero from '../../components/hero';
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { graphql } from 'gatsby'
import { Stack } from 'raam'
import Article, { Byline } from '../../components/article'
import Feed from '../../components/feed'
import { responsive } from '../../utils/helpers'
import Promo from '../../components/promo'
import moment from 'moment'
import Helmet from 'react-helmet'
import MetaData from '../../components/metadata'

const NewsPage = (props) => {

  const page = props.data?.contentfulNews
  const recommended = page?.recommended?.map((x,_) => {
    return {node: x}
  })
  const hasByline = page.byline

  const hero = page?.hero
  const featureHero = 
    hero && hero.length
      ? hero[0]
      : false
  const featureImage = 
    featureHero 
      ? featureHero.foregroundImage?.file?.url
      : false

  return (
    <Layout {...props}>
      
      <MetaData title={page?.title} />

      <Helmet>
        {featureImage &&
          <meta property='og:image' content={ featureImage } />
        }
        <meta property='og:locale' content='en_GB' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={page.title} />
        <meta property='og:url' content={`https://www.fgr.co.uk/news/${page.slug}`} />
        <meta property='og:description' content={page.excerpt} />
        <meta property='og:updated_time' content={page.date} />
      </Helmet>

      <Hero data={hero} sx={{marginBottom: hasByline ? '0px' : 8}} centeredTitle={true} back='/news' />

      {hasByline &&
        <Byline left={moment(page.date).format('MMMM Do YYYY')} right={page.byline} />
      }
      
      <Stack gap={4} my={10}>
        <Wrapper>
          <Inner>
            <Article {...page.article} />
          </Inner>
        </Wrapper>
        {page.recommended &&
          <Wrapper>
            <Inner>              
              <Feed 
                itemsToShow={2} 
                featured 
                sx={{paddingTop: responsive(9, 9)}} 
                showTitle 
                title={{label: "Recommended"}} 
                data={{allContentfulNews: {edges: recommended}}} 
              />
            </Inner>
          </Wrapper>
        }
        {page?.promo &&
          <Wrapper>
            <Inner>
              <Promo data={page?.promo} dark={props.light} />
            </Inner>
          </Wrapper>
        }
      </Stack>
      
    </Layout>
  )
}

export default NewsPage

export const pageQuery = graphql`
  query NewsBySlug($slug: String!) {
    contentfulNews(slug: {eq: $slug}) {
      title
      slug
      excerpt
      byline
      date
      hero {
        ...HeroFragment
      }
      article {
        ... on ContentfulArticle {
          title
          main {
            raw
            references {
              ...ArticleFragment
            }
          }
          aside {
            raw
            references {
              ...ArticleFragment
            }
          }
        }
      }
      recommended {
        title
        slug
        video
        videoEmbed
        date
        excerpt
        thumbnail {
          file {
            url
          }
        }
      }
      promo {
        ...PromoFragment
      }
    }
  }
`